export default {
  "pageLiveCourse": [
    {
      "params": {
        "slug": "business-team-training"
      }
    },
    {
      "params": {
        "slug": "business-admin-training"
      }
    },
    {
      "params": {
        "slug": "dropbox-fundamentals-training"
      }
    },
    {
      "params": {
        "slug": "help-desk-overview-training"
      }
    },
    {
      "params": {
        "slug": "paper-training"
      }
    },
    {
      "params": {
        "slug": "l3-virtual-training-dropbox-sign-training"
      }
    }
  ],
  "pageLanding": [
    {
      "params": {
        "slug": "virtual-training"
      }
    },
    {
      "params": {
        "slug": "home"
      }
    },
    {
      "params": {
        "slug": "self-guided-learning"
      }
    },
    {
      "params": {
        "slug": "topics"
      }
    },
    {
      "params": {
        "slug": "page-profile"
      }
    },
    {
      "params": {
        "slug": "nonprofit-courses"
      }
    }
  ],
  "pageCourse": [
    {
      "params": {
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "slug": "help-desk-course"
      }
    },
    {
      "params": {
        "slug": "dropbox-capture-course"
      }
    },
    {
      "params": {
        "slug": "dropbox-replay-course"
      }
    },
    {
      "params": {
        "slug": "nonprofit-team-member-getting-started"
      }
    },
    {
      "params": {
        "slug": "nonprofit-team-member-get-more-from-dropbox"
      }
    },
    {
      "params": {
        "slug": "nonprofit-admin-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "slug": "nonprofit-admin-advanced-team-management"
      }
    },
    {
      "params": {
        "slug": "dropbox-dash-course"
      }
    },
    {
      "params": {
        "slug": "microsoft-co-authoring-for-dropbox-course"
      }
    },
    {
      "params": {
        "slug": "admin-deployment-guide"
      }
    }
  ],
  "pageCourseTopic": [
    {
      "params": {
        "slug": "team-member-what-is-dropbox"
      }
    },
    {
      "params": {
        "slug": "how-can-i-support-my-teams-users-with-dropbox"
      }
    },
    {
      "params": {
        "slug": "what-tools-are-available-to-me"
      }
    },
    {
      "params": {
        "slug": "common-team-member-challenges"
      }
    },
    {
      "params": {
        "slug": "troubleshooting-shared-file-issues"
      }
    },
    {
      "params": {
        "slug": "troubleshooting-file-update-issues"
      }
    },
    {
      "params": {
        "slug": "troubleshooting-a-shared-link-error"
      }
    },
    {
      "params": {
        "slug": "troubleshooting-accidental-file-deletion"
      }
    },
    {
      "params": {
        "slug": "troubleshooting-storage-space-issues"
      }
    },
    {
      "params": {
        "slug": "team-member-getting-your-account-set-up"
      }
    },
    {
      "params": {
        "slug": "team-member-where-and-how-do-i-save-my-stuff"
      }
    },
    {
      "params": {
        "slug": "team-member-how-do-i-share-and-collaborate-with-my-team"
      }
    },
    {
      "params": {
        "slug": "team-member-how-can-dropbox-help-my-team-work-together"
      }
    },
    {
      "params": {
        "slug": "fundamentals-getting-your-account-set-up"
      }
    },
    {
      "params": {
        "slug": "team-member-how-designers-and-creators-use-dropbox"
      }
    },
    {
      "params": {
        "slug": "team-member-how-project-leaders-use-dropbox"
      }
    },
    {
      "params": {
        "slug": "fundamentals-what-is-dropbox"
      }
    },
    {
      "params": {
        "slug": "fundamentals-where-and-how-do-i-save-my-stuff"
      }
    },
    {
      "params": {
        "slug": "fundamentals-how-do-i-share-with-others"
      }
    },
    {
      "params": {
        "slug": "fundamentals-how-can-dropbox-help-me-collaborate-with-others"
      }
    },
    {
      "params": {
        "slug": "fundamentals-how-do-i-request-stuff-from-others"
      }
    },
    {
      "params": {
        "slug": "fundamentals-how-can-i-get-help"
      }
    },
    {
      "params": {
        "slug": "fundamentals-how-do-i-use-paper"
      }
    },
    {
      "params": {
        "slug": "what-does-it-mean-to-be-an-admin-for-dropbox"
      }
    },
    {
      "params": {
        "slug": "what-can-i-do-through-the-admin-console"
      }
    },
    {
      "params": {
        "slug": "using-dropbox-to-automate-business-processes"
      }
    },
    {
      "params": {
        "slug": "how-do-i-get-started"
      }
    },
    {
      "params": {
        "slug": "how-do-i-control-my-teams-access-to-dropbox"
      }
    },
    {
      "params": {
        "slug": "how-do-i-set-up-admin-access-for-others"
      }
    },
    {
      "params": {
        "slug": "what-can-i-do-to-make-my-teams-account-even-more-secure"
      }
    },
    {
      "params": {
        "slug": "how-do-i-set-team-wide-permissions"
      }
    },
    {
      "params": {
        "slug": "how-do-i-manage-my-organizations-data"
      }
    },
    {
      "params": {
        "slug": "using-a-third-party-technology-partner"
      }
    },
    {
      "params": {
        "slug": "integrating-with-your-identity-provider"
      }
    },
    {
      "params": {
        "slug": "connecting-to-our-scripting-community"
      }
    },
    {
      "params": {
        "slug": "life-of-an-admin-at-a-large-business"
      }
    },
    {
      "params": {
        "slug": "life-of-an-admin-at-a-small-business"
      }
    },
    {
      "params": {
        "slug": "how-to-use-dropbox"
      }
    },
    {
      "params": {
        "slug": "dropbox-admin-guide"
      }
    },
    {
      "params": {
        "slug": "advanced-features-of-the-admin-console"
      }
    },
    {
      "params": {
        "slug": "dropbox-for-business"
      }
    },
    {
      "params": {
        "slug": "team-member-how-do-i-use-dropbox-paper"
      }
    },
    {
      "params": {
        "slug": "business-tips-and-tricks"
      }
    },
    {
      "params": {
        "slug": "fundamentals-tips-and-tricks"
      }
    },
    {
      "params": {
        "slug": "dropbox-capture-guide"
      }
    },
    {
      "params": {
        "slug": "dropbox-replay-guide"
      }
    },
    {
      "params": {
        "slug": "nonprofit-team-member-whats-dropbox-for"
      }
    },
    {
      "params": {
        "slug": "nonprofit-admin-whats-dropbox-for"
      }
    },
    {
      "params": {
        "slug": "nonprofit-what-is-dropbox"
      }
    },
    {
      "params": {
        "slug": "nonprofit-team-member-welcome-video"
      }
    },
    {
      "params": {
        "slug": "nonprofit-where-and-how-do-i-save-my-stuff"
      }
    },
    {
      "params": {
        "slug": "nonprofit-how-do-i-share-with-others"
      }
    },
    {
      "params": {
        "slug": "nonprofit-how-can-dropbox-help-me-collaborate-with-others"
      }
    },
    {
      "params": {
        "slug": "nonprofit-how-do-i-request-stuff-from-others"
      }
    },
    {
      "params": {
        "slug": "nonprofit-how-do-i-use-dropbox-paper"
      }
    },
    {
      "params": {
        "slug": "nonprofit-dropbox-tips-and-tricks"
      }
    },
    {
      "params": {
        "slug": "nonprofit-dropbox-capture"
      }
    },
    {
      "params": {
        "slug": "nonprofit-dropbox-replay"
      }
    },
    {
      "params": {
        "slug": "nonprofit-drop-in-for-success-webinars"
      }
    },
    {
      "params": {
        "slug": "nonprofit-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "slug": "nonprofit-admin-welcome-videos"
      }
    },
    {
      "params": {
        "slug": "nonprofit-what-can-i-do-through-the-admin-console"
      }
    },
    {
      "params": {
        "slug": "nonprofit-how-do-i-set-team-wide-permissions"
      }
    },
    {
      "params": {
        "slug": "nonprofit-how-do-i-control-my-teams-access-to-dropbox"
      }
    },
    {
      "params": {
        "slug": "nonprofit-how-do-i-manage-my-organizations-data"
      }
    },
    {
      "params": {
        "slug": "nonprofit-how-do-i-set-up-admin-access-for-others"
      }
    },
    {
      "params": {
        "slug": "nonprofit-what-can-i-do-to-make-my-teams-account-even-more-secure"
      }
    },
    {
      "params": {
        "slug": "nonprofit-advanced-features-of-the-admin-console"
      }
    },
    {
      "params": {
        "slug": "nonprofit-admin-interactive-tutorial"
      }
    },
    {
      "params": {
        "slug": "nonprofit-team-member-interactive-tutorial"
      }
    },
    {
      "params": {
        "slug": "dropbox-dash-guide"
      }
    },
    {
      "params": {
        "slug": "microsoft-co-authoring-for-dropbox-guide"
      }
    },
    {
      "params": {
        "slug": "plan-and-configure"
      }
    },
    {
      "params": {
        "slug": "deploy-and-empower-your-team"
      }
    },
    {
      "params": {
        "slug": "manage-and-optimize"
      }
    },
    {
      "params": {
        "slug": "additional-resources"
      }
    },
    {
      "params": {
        "slug": "get-started"
      }
    }
  ],
  "pageCourseTopicsV2": [],
  "pageVideo": [
    {
      "params": {
        "videoslug": "drop-in-for-success-q1"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-italian"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-spanish"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-german"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-japanese"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-french"
      }
    },
    {
      "params": {
        "videoslug": "single-sign-on-sso"
      }
    },
    {
      "params": {
        "videoslug": "dropbox-replay-overview"
      }
    },
    {
      "params": {
        "videoslug": "dropbox-capture-intro"
      }
    },
    {
      "params": {
        "videoslug": "dropbox-backup-overview"
      }
    },
    {
      "params": {
        "videoslug": "dropbox-team-space-overview"
      }
    },
    {
      "params": {
        "videoslug": "how-to-view-activity-log"
      }
    },
    {
      "params": {
        "videoslug": "activate-ransomware-protection"
      }
    },
    {
      "params": {
        "videoslug": "manage-security-alerts"
      }
    },
    {
      "params": {
        "videoslug": "remove-member-from-legal-hold"
      }
    },
    {
      "params": {
        "videoslug": "team-folders-overview"
      }
    },
    {
      "params": {
        "videoslug": "dropbox-one-login-sso"
      }
    },
    {
      "params": {
        "videoslug": "install-dropbox-ad-connector"
      }
    },
    {
      "params": {
        "videoslug": "dropbox-capture-overview"
      }
    },
    {
      "params": {
        "videoslug": "data-governance-overview"
      }
    },
    {
      "params": {
        "videoslug": "set-sharing-permissions"
      }
    },
    {
      "params": {
        "videoslug": "how-to-set-a-federated-team"
      }
    },
    {
      "params": {
        "videoslug": "close-a-file-request"
      }
    },
    {
      "params": {
        "videoslug": "legal-holds-and-data-governance"
      }
    },
    {
      "params": {
        "videoslug": "get-started-with-paper"
      }
    },
    {
      "params": {
        "videoslug": "sharing-files-and-folders-with-dropbox"
      }
    },
    {
      "params": {
        "videoslug": "admin-console-overview"
      }
    },
    {
      "params": {
        "videoslug": "create-manage-team-folder"
      }
    },
    {
      "params": {
        "videoslug": "enable-early-access-to-dropbox-features"
      }
    },
    {
      "params": {
        "videoslug": "install-sso-dropbox-microsoft-azure"
      }
    },
    {
      "params": {
        "videoslug": "create-and-manage-groups"
      }
    },
    {
      "params": {
        "videoslug": "upload-and-download-files"
      }
    },
    {
      "params": {
        "videoslug": "mxhero-secure-email-links"
      }
    },
    {
      "params": {
        "videoslug": "admin-welcome-video"
      }
    },
    {
      "params": {
        "videoslug": "understand-file-sharing-options"
      }
    },
    {
      "params": {
        "videoslug": "recover-deleted-dropbox-files"
      }
    },
    {
      "params": {
        "videoslug": "team-member-welcome-video"
      }
    },
    {
      "params": {
        "videoslug": "comments-in-dropbox"
      }
    },
    {
      "params": {
        "videoslug": "file-requests-overview"
      }
    },
    {
      "params": {
        "videoslug": "remove-team-member"
      }
    },
    {
      "params": {
        "videoslug": "custom-branding-and-watermarking"
      }
    },
    {
      "params": {
        "videoslug": "install-sso-dropbox-okta"
      }
    },
    {
      "params": {
        "videoslug": "account-capture-for-team-members"
      }
    },
    {
      "params": {
        "videoslug": "mxhero-email-links-malware-detection"
      }
    },
    {
      "params": {
        "videoslug": "ransomware-respond-to-attacks"
      }
    },
    {
      "params": {
        "videoslug": "share-team-subfolder"
      }
    },
    {
      "params": {
        "videoslug": "getting-started-with-dropbox"
      }
    },
    {
      "params": {
        "videoslug": "manage-team-external-sharing"
      }
    },
    {
      "params": {
        "videoslug": "what-is-the-dropbox-badge"
      }
    },
    {
      "params": {
        "videoslug": "manage-device-approvals"
      }
    },
    {
      "params": {
        "videoslug": "file-requests"
      }
    },
    {
      "params": {
        "videoslug": "integrated-image-search"
      }
    },
    {
      "params": {
        "videoslug": "discover-dropbox-transfer"
      }
    },
    {
      "params": {
        "videoslug": "discover-dropbox-file-requests"
      }
    },
    {
      "params": {
        "videoslug": "enable-two-factor-authentication"
      }
    },
    {
      "params": {
        "videoslug": "scan-docs-to-your-account"
      }
    },
    {
      "params": {
        "videoslug": "account-capture-for-admins"
      }
    },
    {
      "params": {
        "videoslug": "merge-team-accounts"
      }
    },
    {
      "params": {
        "videoslug": "dropbox-rewind-overview"
      }
    },
    {
      "params": {
        "videoslug": "how-to-use-dropbox-transfer"
      }
    },
    {
      "params": {
        "videoslug": "what-is-web-session-control"
      }
    },
    {
      "params": {
        "videoslug": "team-activity-report-overview"
      }
    },
    {
      "params": {
        "videoslug": "dropbox-domain-verification"
      }
    },
    {
      "params": {
        "videoslug": "legal-hold-overview"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-japanese"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-italian"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-spanish"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-german"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-french"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q3"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q3-emea"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q3-french"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q3-german"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q3-italian"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q3-japanese"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q3-spanish"
      }
    },
    {
      "params": {
        "videoslug": "organize-work-with-dropbox"
      }
    },
    {
      "params": {
        "videoslug": "how-to-create-a-har-file"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q4-portuguese"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q4-french"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q4-spanish"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q4-japanese"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q4-german"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q4-emea"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q4"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-2024-namer-english"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-2024-german"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-2024-japanese"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-2024-spanish"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-2024-portuguese"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q1-2024-french"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-2024-english"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-2024-german"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-2024-french"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-2024-spanish"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-2024-portuguese"
      }
    },
    {
      "params": {
        "videoslug": "drop-in-for-success-q2-2024-japanese"
      }
    },
    {
      "params": {
        "videoslug": "create-team-folders-admin-console"
      }
    }
  ],
  "pageVideoLanding": [
    {
      "params": {
        "slug": "video-library"
      }
    }
  ],
  "pageCourseTopicNested": [
    {
      "params": {
        "topicslug": "dropbox-admin-guide",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "what-does-it-mean-to-be-an-admin-for-dropbox",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "what-can-i-do-through-the-admin-console",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "how-do-i-get-started",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "how-do-i-set-up-admin-access-for-others",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "how-do-i-set-team-wide-permissions",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "what-can-i-do-to-make-my-teams-account-even-more-secure",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "how-do-i-manage-my-organizations-data",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "how-do-i-control-my-teams-access-to-dropbox",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "integrating-with-your-identity-provider",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "advanced-features-of-the-admin-console",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "using-a-third-party-technology-partner",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "connecting-to-our-scripting-community",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "life-of-an-admin-at-a-small-business",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "life-of-an-admin-at-a-large-business",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "using-dropbox-to-automate-business-processes",
        "slug": "business-admin-course"
      }
    },
    {
      "params": {
        "topicslug": "how-to-use-dropbox",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "fundamentals-what-is-dropbox",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "fundamentals-getting-your-account-set-up",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "fundamentals-where-and-how-do-i-save-my-stuff",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "fundamentals-how-do-i-share-with-others",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "fundamentals-how-can-dropbox-help-me-collaborate-with-others",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "fundamentals-how-do-i-request-stuff-from-others",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "fundamentals-how-can-i-get-help",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "fundamentals-how-do-i-use-paper",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "fundamentals-tips-and-tricks",
        "slug": "dropbox-fundamentals-course"
      }
    },
    {
      "params": {
        "topicslug": "dropbox-for-business",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "team-member-what-is-dropbox",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "team-member-getting-your-account-set-up",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "team-member-where-and-how-do-i-save-my-stuff",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "team-member-how-do-i-share-and-collaborate-with-my-team",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "team-member-how-can-dropbox-help-my-team-work-together",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "team-member-how-do-i-use-dropbox-paper",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "business-tips-and-tricks",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "team-member-how-designers-and-creators-use-dropbox",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "team-member-how-project-leaders-use-dropbox",
        "slug": "business-team-member-course"
      }
    },
    {
      "params": {
        "topicslug": "how-can-i-support-my-teams-users-with-dropbox",
        "slug": "help-desk-course"
      }
    },
    {
      "params": {
        "topicslug": "what-tools-are-available-to-me",
        "slug": "help-desk-course"
      }
    },
    {
      "params": {
        "topicslug": "common-team-member-challenges",
        "slug": "help-desk-course"
      }
    },
    {
      "params": {
        "topicslug": "troubleshooting-shared-file-issues",
        "slug": "help-desk-course"
      }
    },
    {
      "params": {
        "topicslug": "troubleshooting-file-update-issues",
        "slug": "help-desk-course"
      }
    },
    {
      "params": {
        "topicslug": "troubleshooting-a-shared-link-error",
        "slug": "help-desk-course"
      }
    },
    {
      "params": {
        "topicslug": "troubleshooting-accidental-file-deletion",
        "slug": "help-desk-course"
      }
    },
    {
      "params": {
        "topicslug": "troubleshooting-storage-space-issues",
        "slug": "help-desk-course"
      }
    },
    {
      "params": {
        "topicslug": "dropbox-capture-guide",
        "slug": "dropbox-capture-course"
      }
    },
    {
      "params": {
        "topicslug": "dropbox-replay-guide",
        "slug": "dropbox-replay-course"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-team-member-whats-dropbox-for",
        "slug": "nonprofit-team-member-getting-started"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-what-is-dropbox",
        "slug": "nonprofit-team-member-getting-started"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-team-member-welcome-video",
        "slug": "nonprofit-team-member-getting-started"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-where-and-how-do-i-save-my-stuff",
        "slug": "nonprofit-team-member-getting-started"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-how-do-i-share-with-others",
        "slug": "nonprofit-team-member-getting-started"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-how-can-dropbox-help-me-collaborate-with-others",
        "slug": "nonprofit-team-member-getting-started"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-team-member-interactive-tutorial",
        "slug": "nonprofit-team-member-getting-started"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-how-do-i-request-stuff-from-others",
        "slug": "nonprofit-team-member-get-more-from-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-how-do-i-use-dropbox-paper",
        "slug": "nonprofit-team-member-get-more-from-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-dropbox-tips-and-tricks",
        "slug": "nonprofit-team-member-get-more-from-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-dropbox-capture",
        "slug": "nonprofit-team-member-get-more-from-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-dropbox-replay",
        "slug": "nonprofit-team-member-get-more-from-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-drop-in-for-success-webinars",
        "slug": "nonprofit-team-member-get-more-from-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-admin-whats-dropbox-for",
        "slug": "nonprofit-admin-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-getting-your-team-on-dropbox",
        "slug": "nonprofit-admin-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-admin-welcome-videos",
        "slug": "nonprofit-admin-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-what-can-i-do-through-the-admin-console",
        "slug": "nonprofit-admin-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-how-do-i-set-team-wide-permissions",
        "slug": "nonprofit-admin-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-how-do-i-manage-my-organizations-data",
        "slug": "nonprofit-admin-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-how-do-i-control-my-teams-access-to-dropbox",
        "slug": "nonprofit-admin-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-admin-interactive-tutorial",
        "slug": "nonprofit-admin-getting-your-team-on-dropbox"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-how-do-i-set-up-admin-access-for-others",
        "slug": "nonprofit-admin-advanced-team-management"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-what-can-i-do-to-make-my-teams-account-even-more-secure",
        "slug": "nonprofit-admin-advanced-team-management"
      }
    },
    {
      "params": {
        "topicslug": "nonprofit-advanced-features-of-the-admin-console",
        "slug": "nonprofit-admin-advanced-team-management"
      }
    },
    {
      "params": {
        "topicslug": "dropbox-dash-guide",
        "slug": "dropbox-dash-course"
      }
    },
    {
      "params": {
        "topicslug": "microsoft-co-authoring-for-dropbox-guide",
        "slug": "microsoft-co-authoring-for-dropbox-course"
      }
    },
    {
      "params": {
        "topicslug": "get-started",
        "slug": "admin-deployment-guide"
      }
    },
    {
      "params": {
        "topicslug": "plan-and-configure",
        "slug": "admin-deployment-guide"
      }
    },
    {
      "params": {
        "topicslug": "deploy-and-empower-your-team",
        "slug": "admin-deployment-guide"
      }
    },
    {
      "params": {
        "topicslug": "manage-and-optimize",
        "slug": "admin-deployment-guide"
      }
    },
    {
      "params": {
        "topicslug": "additional-resources",
        "slug": "admin-deployment-guide"
      }
    }
  ]
};